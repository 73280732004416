var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-modal",
        {
          attrs: {
            width: "60%",
            height: "90%",
            title: _vm.title,
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: true,
            "mask-closable": false,
          },
          on: { close: _vm.onClose },
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("div", { staticClass: "approval" }, [
            _c(
              "div",
              { staticClass: "approval-left" },
              [
                _c(
                  "div",
                  { staticClass: "approval-content" },
                  [
                    _vm.approvalStatusImageUrl
                      ? _c("img", {
                          staticClass: "approval-status-image",
                          attrs: { src: _vm.approvalStatusImageUrl },
                        })
                      : _vm._e(),
                    _vm._t("approvalHeader"),
                  ],
                  2
                ),
                _vm.billImageUrl && _vm.billImageUrl.length > 0
                  ? [
                      _c("div", { staticClass: "approval-upload" }, [
                        _vm._v("上传单据"),
                      ]),
                      _c("image-view", {
                        attrs: { "image-url": _vm.billImageUrl },
                      }),
                    ]
                  : _vm._e(),
                _vm._t("aprrovalDetail"),
                _vm.action
                  ? _c("div", [
                      _vm.action == "approval"
                        ? _c(
                            "div",
                            { staticClass: "approval-reason" },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "审批意见" } },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.comment,
                                      callback: function ($$v) {
                                        _vm.comment = $$v
                                      },
                                      expression: "comment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "aprroval-btns" }, [
                        _c("div", { staticClass: "bottom-control" }, [
                          _vm.action == "approval"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "a-space",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.submitForm(4)
                                            },
                                          },
                                        },
                                        [_vm._v(" 同意 ")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            loading: _vm.loading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.submitForm(2)
                                            },
                                          },
                                        },
                                        [_vm._v(" 不同意 ")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { loading: _vm.loading },
                                          on: {
                                            click: function ($event) {
                                              return _vm.submitForm(6)
                                            },
                                          },
                                        },
                                        [_vm._v(" 暂停 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.action == "reject"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "a-space",
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleReject(_vm.form)
                                            },
                                          },
                                        },
                                        [_vm._v(" 撤回 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm.approvalRecordVos && _vm.approvalRecordVos.length > 0
              ? _c(
                  "div",
                  { staticClass: "approval-right" },
                  [
                    _c(
                      "a-steps",
                      {
                        staticClass: "approval-step",
                        attrs: {
                          current: _vm.approvalIndex,
                          size: "small",
                          status: _vm.approveStatus,
                          direction: "vertical",
                        },
                      },
                      _vm._l(_vm.approvalRecordVos, function (item, index) {
                        return _c(
                          "a-step",
                          {
                            key: index,
                            attrs: { status: _vm.approveStatusItem(item) },
                          },
                          [
                            item.approvalAction == "100"
                              ? [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-tongzhi to-icon",
                                    attrs: { slot: "icon" },
                                    slot: "icon",
                                  }),
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(item.approvalBy) + " "),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "description" },
                                      slot: "description",
                                    },
                                    [
                                      _vm._v(
                                        "共" +
                                          _vm._s(item.notifyUsers.length) +
                                          "人"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "description" },
                                      slot: "description",
                                    },
                                    _vm._l(
                                      item.notifyUsers,
                                      function (item2, index2) {
                                        return _c(
                                          "span",
                                          {
                                            key: index2,
                                            staticClass: "notify-user-name",
                                          },
                                          [_vm._v(_vm._s(item2))]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.approvalBy || item.assignee
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "description" },
                                      slot: "description",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.approvalStatusFormat(item))
                                      ),
                                    ]
                                  ),
                                  item.approvalAction == "2" ||
                                  item.approvalAction == "4" ||
                                  item.approvalAction == "6"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "approval-time",
                                          attrs: { slot: "description" },
                                          slot: "description",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.comment) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "approval-time",
                                      attrs: { slot: "description" },
                                      slot: "description",
                                    },
                                    [_vm._v(_vm._s(item.approvalTime))]
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }