<template>
  <!-- 申请单详情与审批9模板页面 -->
  <div>
    <vxe-modal v-model="open" width="60%" height="90%" :title="title" :show-zoom="true" resize :lock-view="false" :mask="true" :mask-closable="false" @close="onClose">
      <div class="approval">
        <div class="approval-left">
          <div class="approval-content">
            <img class="approval-status-image" :src="approvalStatusImageUrl" v-if="approvalStatusImageUrl" />
            <!-- 审批头部内容 -->
            <slot name="approvalHeader"></slot>
          </div>
          <template v-if="billImageUrl && billImageUrl.length > 0">
            <div class="approval-upload">上传单据</div>
            <image-view :image-url="billImageUrl" />
          </template>

          <!-- 审批明细内容 -->
          <slot name="aprrovalDetail"></slot>
          <!-- 审批动作 -->
          <div v-if="action">
            <div class="approval-reason" v-if="action == 'approval'">
              <a-form-item label="审批意见"> <a-input placeholder="请输入" v-model="comment" /></a-form-item>
            </div>
            <div class="aprroval-btns">
              <div class="bottom-control">
                <!-- 费用审批 -->
                <div v-if="action == 'approval'">
                  <a-space>
                    <a-button type="primary" :loading="loading" @click="submitForm(4)"> 同意 </a-button>
                    <a-button type="danger" :loading="loading" @click="submitForm(2)"> 不同意 </a-button>
                    <a-button :loading="loading" @click="submitForm(6)"> 暂停 </a-button>
                  </a-space>
                </div>
                <!-- 撤回 -->
                <div v-if="action == 'reject'">
                  <a-space>
                    <a-button type="primary" :loading="loading" @click="handleReject(form)"> 撤回 </a-button>
                  </a-space>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="approval-right" v-if="approvalRecordVos && approvalRecordVos.length > 0">
          <a-steps :current="approvalIndex" size="small" class="approval-step" :status="approveStatus" direction="vertical">
            <a-step v-for="(item, index) in approvalRecordVos" :status="approveStatusItem(item)" :key="index">
              <!-- 抄送人处理 -->
              <template v-if="item.approvalAction == '100'">
                <!-- <img :src="voiceUrl" slot="icon" /> -->
                <i class="iconfont icon-tongzhi to-icon" slot="icon" />
                <template slot="title">
                  {{ item.approvalBy }}
                </template>
                <span slot="description">共{{ item.notifyUsers.length }}人</span>
                <div slot="description">
                  <span class="notify-user-name" v-for="(item2, index2) in item.notifyUsers" :key="index2">{{ item2 }}</span>
                </div>
              </template>
              <!-- 审批节点处理 -->
              <template v-else>
                <template slot="title">
                  {{ item.approvalBy || item.assignee }}
                </template>

                <span slot="description">{{ approvalStatusFormat(item) }}</span>
                <!-- 显示拒绝与暂停原因 -->
                <div slot="description" class="approval-time" v-if="item.approvalAction == '2' || item.approvalAction == '4' || item.approvalAction == '6'">
                  {{ item.comment }}
                </div>

                <div slot="description" class="approval-time">{{ item.approvalTime }}</div>
              </template>
            </a-step>
          </a-steps>
        </div>
      </div>
    </vxe-modal>
  </div>
</template>

<script>
import { getApprovalProcess, completeApproval } from '@/api/iot/approvalProcessForm'
import { Steps } from 'ant-design-vue'
import ImageView from '@/components/ImageView'
export default {
  name: 'ApprovalForm',
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    ImageView
  },
  props: {
    title: {
      type: String,
      required: true
    },
    approvalRecord: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      required: true
    },
    action: {
      type: String,
      default: ''
    },
    billImageUrl: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      // 当前审批索引
      approvalIndex: 0,
      approvalRecordVos: [],
      comment: ''
    }
  },
  mounted() {
    console.log('open', this.open)
    this.getApprovalProcessContent()
  },
  computed: {
    approveStatus() {
      var approvalResult = null
      if (this.approvalRecordVos && this.approvalRecordVos.length > 0) {
        approvalResult = this.approvalRecordVos[this.approvalIndex - 1].approvalAction
      }
      switch (approvalResult) {
        case '2':
        case '3':
          return 'error'
        case '4':
          return 'finish'
        default:
          return 'process'
      }
    },
    approvalStatusImageUrl() {
      if (this.approvalRecord.approvalStatus === '2') {
        return require('@/assets/images/refuse.png')
      } else if (this.approvalRecord.approvalStatus === '3') {
        return require('@/assets/images/reject.png')
      } else if (this.approvalRecord.approvalStatus === '4') {
        return require('@/assets/images/pass.png')
      } else if (this.approvalRecord.approvalStatus === '5') {
        return require('@/assets/images/pay.png')
      } else if (this.approvalRecord.approvalStatus === '6') {
        return require('@/assets/images/stop.png')
      } else {
        return ''
      }
    },
    voiceUrl() {
      return require('@/assets/images/voice.png')
    }
  },
  methods: {
    handleOfficePreview(url) {
      const newUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url)
      this.handlePdfPreview(newUrl)
    },
    onClose() {
      this.$emit('onClose')
    },
    approvalStatusFormat(item) {
      if (item.approvalAction) {
        switch (item.approvalAction) {
          case '1':
            return '待审批'
          case '2':
            return '拒绝'
          case '3':
            return '已撤销'
          case '4':
            return '同意'
          case '6':
            return '暂停'
          default:
            return '流程中'
        }
      } else {
        switch (item.approvalState) {
          case 1:
            return '待审批'
          case 2:
            return '拒绝'
          case 3:
            return '已撤销'
          case 4:
            return '同意'
          case 6:
            return '暂停'
          default:
            return '流程中'
        }
      }
    },
    approveStatusItem(item) {
      const approvalResult = item.approvalAction || item.approvalState + ''
      switch (approvalResult) {
        case '2':
        case '3':
          return 'error'
        case '4':
          return 'finish'
        default:
          return 'wait'
      }
    },
    getApprovalProcessContent() {
      if (this.approvalRecord.processInstanceId) {
        getApprovalProcess(this.approvalRecord.processInstanceId).then((response) => {
          this.approvalRecordVos = response.data.approvalRecordVos
          this.approvalIndex = this.approvalRecordVos.length
          this.userTasks = response.data.userTasks
          const notifyUsersVo = response.data.notifyUsersVo
          let notifyUsers = []
          if (notifyUsersVo && notifyUsersVo.notifyUsers) {
            notifyUsers = notifyUsersVo.notifyUsers.split(',')
          }
          if (this.approvalRecord.approvalStatus !== '3') {
            for (const item of this.userTasks) {
              if (item.approvalState === 1) {
                this.approvalRecordVos.push(item)
              }
            }

            if (notifyUsers.length > 0) {
              this.approvalRecordVos.push({
                approvalAction: '100',
                approvalBy: '抄送人',
                notifyUsers: notifyUsers,
                status: 0
              })
            }
          } else {
            this.approvalRecordVos.push({
              approvalAction: '3',
              approvalBy: this.approvalRecordVos[0].approvalBy,
              approvalKind: this.approvalRecordVos[0].approvalKind,
              approvalKindId: this.approvalRecordVos[0].approvalKindId,
              approvalResult: '0',
              approvalTime: this.approvalRecord.updateTime,
              createBy: this.approvalRecordVos[0].createBy,
              status: 0
            })
          }

          if (this.approvalIndex > 0) {
            this.approvalResult = this.approvalRecordVos[this.approvalIndex - 1].approvalAction
            if (this.approvalResult !== '4') {
              this.approvalIndex = this.approvalIndex > 0 ? this.approvalIndex - 1 : 0
            }
            switch (this.approvalResult) {
              case '2':
              case '3':
                this.approveStatus = 'error'
                break
              case '4':
                this.approveStatus = 'finish'
                break
              default:
                this.approveStatus = 'process'
            }
          }
        })
      }
    },

    /** 撤回操作 */
    handleReject(row) {
      const that = this
      const record = Object.assign({}, row)
      record.approvalStatus = 3
      this.$confirm({
        title: '确认撤回所选中数据?',
        content: '',
        zIndex: 99999,
        onOk() {
          that.submitForm(3)
        },
        onCancel() {}
      })
    },
    // 审批相关操作
    submitForm(approvalResult) {
      const taskId = this.approvalRecord.taskId
      const businessKey = this.approvalRecord.approvalKindId
      const definitionKey = this.approvalRecord.approvalKind
      const params = {
        approvalResult: approvalResult,
        businessKey: businessKey,
        comment: this.comment,
        definitionKey: definitionKey,
        taskId: taskId
      }
      this.loading = true
      completeApproval(params)
        .then((response) => {
          this.loading = false
          if (response) {
            this.open = false
            this.$emit('ok')
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="less">
.approval-step {
  .ant-steps-item {
    min-width: 160px;
    margin-bottom: 20px;
  }
}
.approval-content {
  position: relative;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
  .ant-row {
    padding-top: 10px;
  }
  .ant-row:first-child {
    padding-top: 10px;
  }
  .ant-row:last-child {
    padding-bottom: 10px;
  }
}
.approval-select {
  margin: 20px 0;
}
.approval-reason {
  margin-top: 10px;
  margin-bottom: 20px;
  .ant-form-item-label {
    margin-right: 10px;
  }
  .ant-form-item {
    display: flex;
    width: 100%;
  }
  .ant-form-item-control-wrapper {
    width: 100% !important;
  }
  .expense-content-item {
    > .left {
      height: 40px;
      line-height: 40px;
    }
  }
}
.expense-content-title,
.expense-content-item {
  display: flex;
  height: 40px;
  .expense-col {
    width: 25% !important;
    margin: 0 3px;
    text-align: center;
  }
  .first-col {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  > .left {
    flex: 0 0 35px;
    min-width: 35px;
    text-align: right;
    padding-right: 3px;
  }
}

.expense-content-title {
  .expense-col {
    font-weight: bold;
    text-align: center;
  }
}

.approval {
  display: flex;
  height: 100%;
  .approval-left {
    flex: 1;
    margin-top: 10px;
    padding-right: 10px;
  }
  .approval-right {
    flex: 0 0 300px;
    padding-left: 10px;
    padding-top: 20px;
    border-left: 1px solid #e8e8e8;
    // height: 99%;
    // overflow: auto;
    .to-icon {
      font-size: 26px;
      color: #1296db;
    }
  }
}
.aprroval-btns {
  .bottom-control {
    margin-top: 20px;
    text-align: center;
    border-top: 0 !important;
  }
}
.ant-drawer-approval {
  .ant-drawer-body {
    margin-top: 30px !important;
    color: #29abe2;
  }
}
.approval-status-image {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 32px;
  right: 32px;
  z-index: 9999;
}

.approval-left .anticon-delete {
  display: none !important;
}
.approval-upload {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 550;
}
.image-modal {
  z-index: 99999;
}
.approval-images {
  display: flex;
  flex-wrap: wrap;
  img,
  span {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 8px;
  }
  span {
    display: inline-block;
    text-align: center;
    line-height: 80px;
  }
  .view-item {
    display: inline-block;
  }
}
.notify-user-name {
  margin-right: 10px;
}
</style>
